/* font.css */
@font-face {
  font-family: "HelveticaLTPro-Bold";
  src: url("/public/font/HelveticaLTPro-Bold.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaLTPro-Black";
  src: url("/public/font/HelveticaLTPro-Black.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaLTPro-Light";
  src: url("/public/font/HelveticaLTPro-Light.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HelveticaLTPro-Roman";
  src: url("/public/font/HelveticaLTPro-Roman.woff") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 12px;
}

input {
  font-family: "HelveticaLTPro-Light";
}

body {
  touch-action: none;
}

/* floating */
input[type="text"]::-webkit-input-placeholder {
  color: #c4c4c4;
  font-family: "HelveticaLTPro-Roman";
}

input[type="number"]::-webkit-input-placeholder {
  color: #c4c4c4;
  font-family: "HelveticaLTPro-Roman";
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.custom-select {
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  font-size: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  appearance: none; /* Remove default select dropdown arrow */
  -webkit-appearance: none; /* Remove default select dropdown arrow */
  -moz-appearance: none; /* Remove default select dropdown arrow */
}

#loadingVideo .dot {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(219, 205, 205);
  animation: wave 1s linear infinite;
}

#loadingVideo .dot:nth-child(2) {
  animation-delay: 0.2s;
}

#loadingVideo .dot:nth-child(3) {
  animation-delay: 0.4s;
}

#loadingVideo .dotContainer {
  display: flex;
  width: 20vw;

  justify-content: space-between;
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
